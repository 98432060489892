import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import * as styles from "./slider.module.css"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle as fasFaCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircle as farFaCircle } from '@fortawesome/free-regular-svg-icons'

/*
 * Based on https://css-tricks.com/can-get-pretty-far-making-slider-just-html-css/
 */

const Slider = ({ data }) => {
  const [visibleSlide, setVisibleSlide] = useState(0);

  useEffect(() => {
    let observeSlide = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target && entry.target.id) {
            setVisibleSlide(parseInt(entry.target.id.split('-').pop()));
          }
        }
      });
    };

    let observer = new IntersectionObserver(observeSlide, {
      root: document.querySelector('#slider'),
      rootMargin: '0px',
      threshold: 0.5
    });
    for (let i = 0; i < 10; i++) {
      let target = document.querySelector('#slide-' + i);
      if (target) {
        observer.observe(target);
      }
    }
  }, []);

  const normalizeDate = (date) => {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = date.getFullYear();
    return yyyy + mm + dd;
  };

  if (data == null || typeof data === "undefined") {
    return null
  } else {
    // filter to those posted today or earlier
    const published = [];
    const now = normalizeDate(new Date());

    const handleNavClick = (event) => {
      console.log("clicked");
    }

    let counter = 0;
    data.forEach(node => {
      if (counter >= 10) {
        return;
      }
      const postDate = normalizeDate(new Date(node.frontmatter.date));
      if (postDate <= now) {
        published.push(node);
        counter += 1;
      }
    });

    return (
      <div>
        <div className={styles.slider} id='#slider' title="Scroll for more">
        {published.map((node, index) => (
          <div key={'slide-' + index} className={styles.slide} id={'slide-' + index}>
            <Link to={node.fields.slug}>
              {node.fields.thumbnail && 
                <GatsbyImage image={getImage(node.fields.thumbnail)} alt={node.frontmatter.title + ' from ' + node.frontmatter.source} /> 
              }
            </Link>
          </div>
        ))}
        </div>
        <div className={styles.sliderNav}>
        {published.map((node, index) => (        
          <a key={'slide-nav-' + index} aria-label={index} href={'#slide-' + index} onClick={handleNavClick}>
            {visibleSlide === index && 
              <FontAwesomeIcon icon={fasFaCircle} className={styles.navIcon} size="sm" />
            } 
            {visibleSlide !== index &&
              <FontAwesomeIcon icon={farFaCircle} className={styles.navIcon} size="sm" />
            }
          </a>
        ))}
        </div>
      </div>
    )
  }
}

Slider.propTypes = {
  data: PropTypes.array
}

Slider.defaultProps = {
  data: []
}

export default Slider